import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconElectricityContract.module.scss';

const IconElectricityContract = ({ shouldStart = false, id = '' }) => {
    const ElectricityContractAnimate = () => {
        anime({
            targets: `.${id}contractLine`,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 1000,
            delay: function (el, i) {
                return i * 250;
            },
            direction: 'forward',
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart ? ElectricityContractAnimate() : null;
    });

    return (
        <div className={styles['IconElectricityContract']}>
            <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 51">
                <path
                    d="m43.775 14.5.6-.8-.6.8zM24.578 1.88l.593.806-.593-.805zM7.613 46V16.111h-2V46h2zm.408-30.694 17.15-12.62-1.186-1.61-17.15 12.62 1.186 1.61zM26.364 2.691 43.174 15.3l1.2-1.6-16.81-12.609-1.2 1.6zM43.574 16.1V46h2V16.1h-2zm-1 30.9H8.615v2h33.96v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2zm-.4-30.7a1 1 0 0 1 .4.8h2a3 3 0 0 0-1.2-2.4l-1.2 1.6zM25.172 2.686a1 1 0 0 1 1.192.005l1.2-1.6a3 3 0 0 0-3.578-.016l1.186 1.611zM7.613 16.111a1 1 0 0 1 .408-.805l-1.186-1.61a3 3 0 0 0-1.222 2.415h2zM5.613 46a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1h-2z"
                    fill="currentColor"
                />
                <path
                    className={id + 'contractLine'}
                    d="m22.291 38.136 1.65-8.252-6.601-2.476 11.553-12.379-1.65 8.253 6.602 2.476L22.29 38.136z"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

IconElectricityContract.propTypes = {};

export default IconElectricityContract;
